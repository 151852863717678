body {
  overflow: hidden;
}

@keyframes left {
  0% {
    opacity: 1;
    transform: var(--pos);
  }

  100% {
    scale: var(--scale);
    transform: translateX(-200%) scale(var(--scale));
    opacity: var(--opacity);
  }
}

@keyframes up {
  0% {
    opacity: 1;
    bottom: var(--bottom);
    transform: var(--pos);
  }

  100% {
    transform: translateX(var(--player-pos)) scale(var(--scale));
    opacity: var(--opacity);
    bottom: 100vh;
  }
}
/*# sourceMappingURL=index.6898f176.css.map */

body {
    overflow: hidden;
}

@keyframes left {
    0% {
        opacity: 1;
        transform: var(--pos);
    }

    100% {
        scale: var(--scale);
        transform:  translateX(-200%) scale(var(--scale));
        opacity: var(--opacity);
        /* scale: var(--scale); */
    }
}

@keyframes up {
    0% {
        opacity: 1;
        bottom: var(--bottom);
        transform:  var(--pos);
    }
    100% {
        bottom: calc(100vh);
        transform:   translateX(var(--player-pos)) scale(var(--scale));
        opacity: var(--opacity);
    }
}
